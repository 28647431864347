import './bootstrap';
import '../css/app.css';

import flatpickrData from '@/flatpickr-data';

window.flatpickrData = flatpickrData;

if (Livewire) {
	Livewire.on('flash', (event) => {
		window.events.$emit('flash', event.message);
	});
}

function setViewHeight() {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}

setViewHeight();
window.addEventListener('resize', setViewHeight);
