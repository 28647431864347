import moment from 'moment';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/themes/dark.css';

export default () => ({
	picker: null,
	init() {
		this.picker = flatpickr(this.$refs.input, {
			altFormat: 'd/m/Y',
			dateFormat: 'Y-m-d',
			altInput: true,
			maxDate: moment().subtract(18, 'year').format('DD/MM/YYYY')
		});
	}
})